import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Box, Flex, Text } from 'rebass/styled-components'
import {
  AccessButtonReverse,
  Container,
  ExternalLink,
  HalfWidthSmallSubtitle,
  SectionBox,
  Subtitle,
  TextImage,
} from '../base'

export const fluidImage1 = graphql`
  fragment fluidImage7 on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
export function TransactionFees(): React.ReactElement {
  const data = useStaticQuery(graphql`
    query {
      HeaderLogo: file(relativePath: { eq: "images/HeaderLogo.png" }) {
        ...fluidImage7
      }
      MerchantsFist: file(relativePath: { eq: "images/MerchantsFist.png" }) {
        ...fluidImage7
      }
      UsingChoiceHandLarge: file(
        relativePath: { eq: "images/UsingChoiceHandLarge.png" }
      ) {
        ...fluidImage7
      }
    }
  `)
  return (
    <SectionBox bg="primary.main">
      <Box width={[0, 0, 1 / 22]} py={[0, 3, 5]}>
        <Img fluid={data.MerchantsFist.childImageSharp.fluid} />
      </Box>
      <Subtitle
        color="primary.text"
        p={[3, 3, 5]}
        px={[0, 3, 0]}
        fontSize={[5, 9, 13]}
        fontWeight="black"
      >
        You&#39;ve been paying way too much in transaction fees.
      </Subtitle>

      <Box pb={[4, 0, 0]} pt={[0, 4, 5]}>
        <Container width={[1, 1, 1 / 2]}>
          <Box px={[0, 3, 0]}>
            <HalfWidthSmallSubtitle color="primary.text" fontSize={[2, 3, 3]}>
              As a Kiwi business, you could pay up to&nbsp;
              <TextImage color="secondary.main">6.5 </TextImage>
            </HalfWidthSmallSubtitle>
            <HalfWidthSmallSubtitle
              color="primary.text"
              pt={[3, 0, 0]}
              fontSize={[2, 3, 3]}
            >
              times less in transaction fees by using&nbsp;
              <TextImage width={[1 / 4, 1 / 6, 1 / 5]}>
                <Img fluid={data.HeaderLogo.childImageSharp.fluid} />
              </TextImage>
            </HalfWidthSmallSubtitle>
          </Box>
        </Container>
        <Container
          width={[1, 1, 1 / 2]}
          py={[0, 4, 5]}
          pr={[4, 0, 0]}
          ml={[0, 0, 580]}
        >
          <Flex justifyContent="center">
            <Box width={[1 / 9, 1 / 16, 1 / 16]} pb={4} ml={[3, 0, 0]}>
              <Img fluid={data.UsingChoiceHandLarge.childImageSharp.fluid} />
            </Box>
          </Flex>
          <AccessButtonReverse
            width={[8 / 10, 1 / 2, 1 / 2]}
            sx={{ borderRadius: 30 }}
            fontSize={[-2, -2, 3]}
            fontWeight="bold"
            ml={[4, 0, 0]}
          >
            <ExternalLink href="https://choicetopay.typeform.com/to/yVYWjv">
              <Text sx={{ color: 'primary.main' }}>Join Waitlist</Text>
            </ExternalLink>
          </AccessButtonReverse>
        </Container>
      </Box>
    </SectionBox>
  )
}
