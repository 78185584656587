import React from 'react'
import { Container } from '../components/base'
import { withLayout } from '../components/Layout'
import { DeservesBetter } from '../components/merchant/DeservesBetter'
import { TransactionFees } from '../components/merchant/TransactionFees'

const MerchantPage = () => {
  return (
    <Container>
      <TransactionFees />
      <DeservesBetter />
    </Container>
  )
}

export default withLayout(MerchantPage)
