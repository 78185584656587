import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Box, Flex, Text } from 'rebass/styled-components'
import {
  AccessButton,
  Container,
  HalfWidthSmallSubtitleDark,
  SectionBox,
  Subtitle,
  SubtitleMain,
  TextImage,
} from '../base'

export function DeservesBetter(): React.ReactElement {
  const data = useStaticQuery(graphql`
    query {
      MerchantsThumbsUp: file(
        relativePath: { eq: "images/MerchantsThumbsUp.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <SectionBox bg="primary.text">
      <SubtitleMain
        pt={[3, 4, 5]}
        px={[0, 3, 0]}
        fontSize={[8, 12, 13]}
        fontWeight="black"
      >
        Enough is enough!
      </SubtitleMain>
      <Subtitle
        color="dark"
        textAlign="start"
        fontSize={[6, 10, 13]}
        fontWeight="black"
        px={[0, 3, 0]}
      >
        New Zealand deserves better
      </Subtitle>

      <Flex py={[1, 4, 4]} px={[0, 3, 0]}>
        <Container width={[0, 0, 1 / 2]} />
        <Container width={[1, 1, 1 / 2]}>
          <Box
            width={[1 / 7, 1 / 10, 1 / 14]}
            pb={[2, 3, 4]}
            pt={[2, 0, 0]}
            ml={['8rem', '20.5rem', '12rem']}
            mt={[0, -4, 0]}
          >
            <Img fluid={data.MerchantsThumbsUp.childImageSharp.fluid} />
          </Box>
          <HalfWidthSmallSubtitleDark width={[1, 1, 6 / 10]}>
            When your customers pay with Choice, you&#39;ll pay a reduced, flat
            rate transaction fee and you&#39;ll be redirecting 50% of it to NZ
            charities!&nbsp;
            <TextImage color="primary.main">It&#39;s a win-win.</TextImage>
          </HalfWidthSmallSubtitleDark>
        </Container>
      </Flex>
      <Box mt={[0, 0, '-6rem']}>
        <AccessButton
          sx={{ borderRadius: 20 }}
          width={[1 / 2, 1 / 2, 1 / 4]}
          mb={[4, 5, 6]}
          ml={['5rem', '12rem', 0]}
        >
          <Link to="/merchant-signup" css={{ textDecoration: 'none' }}>
            <Text color="primary.text">Sign up</Text>
          </Link>
        </AccessButton>
      </Box>
    </SectionBox>
  )
}
